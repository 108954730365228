<template>
  <div>
    <WaBtn />
    <!-- Header -->
    <MainHeader />
    <!-- end Header -->

    <!-- Main Banner -->
    <MainBanner hide-enquiry="true" />
    <!-- end Main Banner -->
    <div class="container py-8 mx-auto text-center lg:py-20">
      <h1 class="pb-6 text-3xl font-semibold text-lite-blue md:text-4xl">
        Message Sending Successful!
      </h1>
      <p class="px-4 text-lg font-semibold text-gray-700 lg:w-3/4 lg:mx-auto ">
        Thank you for your kind interest in Buffbones Orthopaedic Physiotherapy & Rehabilitation. We will get
        back to you as soon as possible.
      </p>
      <router-link to="/" class="block w-48 mx-auto">
        <p class="block py-2 my-5 text-white rounded-lg bg-lite-blue">
          Back To Home
        </p>
      </router-link>
    </div>
    <!-- copyRight -->
    <Copyright />
  </div>
</template>

<script>
import WaBtn from "@/components/WaBtn.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainBanner from "@/components/MainBanner.vue";
import Copyright from "@/components/Copyright.vue"

export default {
  components: {
    WaBtn,
    MainHeader,
    MainBanner,
    Copyright
  },
};
</script>

<style>
</style>